import { makeStyles, withStyles } from "@material-ui/styles";
import React, { useMemo } from "react";
import MasterView from 'presentational/MasterView';

const useStyles = makeStyles({
    root: {
        height: "100%",
        border: 0
    }
});

function EmbeddedService(props) {
    const { metadata, masterView } = props;
    const classes = useStyles();

    const serviceUrl= useMemo(() => {
        if(metadata) {
            let serviceUrl = metadata.url;

            if(metadata.params) {
                const params = Object.entries(metadata.params).map(([name, path]) => {
                    const tokens = path.split(".");
                    return `${name}=${getConfigData(tokens)}`;
                });
                
                params.push("authToken", app.token.get("token"));

                if(params.length > 0) {
                    serviceUrl += `?${params.join("&")}&b=${Date.now()}`;
                }
            }

            return serviceUrl
        }

        return undefined;
    }, [metadata.url, metadata.params])

    return (
        <> 
            {serviceUrl && !masterView && <iframe className={classes.root} src={serviceUrl} /> }
            {serviceUrl && masterView && 
                <MasterView>
                    <div className="component-wrapper" style={{flex: "1"}}>
                        <iframe className={classes.root} src={serviceUrl} /> 
                    </div>
                </MasterView>
            }
        </>
    );
}

function getConfigData(pathItems, context = undefined) {
    if(pathItems.length <= 0) {
        return context;
    }

    const item = pathItems.shift();
    if(!context) {
        return getConfigData(pathItems, App.Configurations.get(item));
    }

    return getConfigData(pathItems, context[item]);

}

export default EmbeddedService;